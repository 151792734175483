
.dataAnalysis-container {
    position: relative;
    /*height: calc(100vh - 100px);*/
    display: flex;
    flex-direction: column;
    .dataAnalysis-wrapper {
        display: flex;
        /*height: 1%;*/
        /*flex: 1;*/
        flex-direction: column;
        background: #fff;
        margin-top: 20px;
        border-radius: 10px;
        padding: 30px 40px 0;
        box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
        .tab-wrapper {
            .tab-item {
                display: inline-block;
                height: 32px;
                padding: 0 22px;
                line-height: 32px;
                background: #F1F0FE;
                color: #584EEE;
                font-size: 16px;
                margin-right: 20px;
                border-radius: 4px;
                transition: all .3s;
                &:last-child {
                    margin-right: 0;
                }
                &.current, &:hover {
                    color: #fff;
                    background: #574DED;
                }
            }
        }
    }
}
.form-custom {
    .el-form-item {
        ::v-deep .el-form-item__label {
            width: 100%;
        }
    }
    .form-item-title {
        line-height: 41px;
        .label-text {
            margin-right: 10px;
        }
        .iconfont {
            cursor: pointer;
        }
    }
}
